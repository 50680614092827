import ResizeObserverPolyfill from "resize-observer-polyfill";
import { fireEvent, sendMessages, sendMessagesToParent } from "./util";
import {
  enableBodyScroll,
  disableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";

// Allow resize an iframe
const onResize = (payload) => {
  const container = document.querySelector(
    `div[data-mc-cmp-id="${payload.id}"]`
  );
  if (
    container &&
    payload.height &&
    !container.getAttribute("data-mc-manual-resizing")
  ) {
    let height = payload.height;
    if (
      window.MC.Loader &&
      window.MC.Loader.resize &&
      Number(window.MC.Loader.resize.scale)
    )
      height = height * Number(window.MC.Loader.resize.scale);
    container.style.height = `${height}px`;
    if (
      container.getAttribute("data-mc-type") === "external-iframe" &&
      container.parentNode &&
      container.parentNode.tagName === "ARTICLE"
    )
      container.parentNode.style.height = `${height}px`;
  }
};
// Calc window height
const retrieveHeight = () => {
  const el =
    (window.MC &&
      window.MC.Loader &&
      window.MC.Loader.resize &&
      window.MC.Loader.resize.selector &&
      document.querySelector(window.MC.Loader.resize.selector)) ||
    document.querySelector(".wx-widget-container") ||
    document.body;
  const len = el.children.length;
  let height = el.offsetHeight;
  if (len > 1 && el !== document.body) {
    height = el.children[len - 1].offsetHeight + el.children[len - 1].offsetTop;
  }

  if (
    window.MC &&
    window.MC.Loader &&
    window.MC.Loader.resize &&
    window.MC.Loader.resize.factor
  )
    height += window.MC.Loader.resize.factor;
  const style = getComputedStyle(el);
  height +=
    parseInt(style.marginTop) +
    parseInt(style.marginBottom) +
    parseInt(style.paddingTop) +
    parseInt(style.paddingBottom);

  return height;
};
// Fire event for parent when component is loaded data
const onDataLoaded = () => {
  sendMessagesToParent("usrc:data-loaded", {
    id: window.MC.USrc.Parent.cpmId,
    height: retrieveHeight(),
  });
};
// Detect when page height changed
const resizeCallback = () => {
  sendMessagesToParent("resize", {
    id: window.MC.USrc.Parent.cpmId,
    height: retrieveHeight(),
  });
};
// Detect when lightbox open
const onOpenLightbox = (payload) => {
  if (!window.MC.USrc.Lightbox) window.MC.USrc.Lightbox = {};
  if (!window.MC.USrc.Lightbox.open)
    fireEvent("open-lightbox", window, payload);
  window.MC.USrc.Lightbox.open = true;
};
// Send messages to open lightbox into parent
const onOpenLightboxParent = (payload) => {
  const iframe = document.querySelector(
    `div[data-mc-lightbox="lightbox-${payload.cpmId}"] iframe`
  );
  if (iframe) {
    disableBodyScroll(iframe.parentElement);
    sendMessages(iframe.contentWindow, "open-lightbox-child", payload.params);
    iframe.parentNode.style.display = "unset";
    setTimeout(() => {
      iframe.parentNode.style.opacity = 1;
    }, 500);
  }
};
// Detect when lightbox close into parent
const onCloseLightboxParent = (payload) => {
  const iframe = document.querySelector(
    `div[data-mc-lightbox="${payload.cpmId}"] iframe`
  );
  if (iframe) {
    enableBodyScroll(iframe.parentElement);
    iframe.parentNode.style.opacity = "0";
    iframe.parentNode.style.display = "none";
  } else {
    clearAllBodyScrollLocks();
  }
};

export const initEvents = ({ appendLightbox = () => {} }) => {
  window.addEventListener("message", (e) => {
    const data = e.data;
    switch (data.event) {
      case "resize":
        onResize(data.payload);
        break;
      case "open-lightbox-child":
        onOpenLightbox(data.payload);
        break;
      case "open-lightbox-parent":
        onOpenLightboxParent(data.payload);
        break;
      case "close-lightbox-parent":
        onCloseLightboxParent(data.payload);
        break;
      case "append-lightbox":
        appendLightbox(data.payload);
        break;
      default:
        break;
    }
  });
  // Listen event for component loaded
  window.addEventListener("data-loaded", onDataLoaded);
  // Listen pubsub lightbox
  window.addEventListener("open-lightbox", (e) => {
    sendMessagesToParent("open-lightbox-parent", {
      params: e.detail,
      cpmId: window.MC.USrc.Parent.cpmId,
    });
  });
  window.addEventListener("close-lightbox", (e, payload) => {
    if (!window.MC.USrc.Lightbox) window.MC.USrc.Lightbox = {};
    window.MC.USrc.Lightbox.open = false;
    sendMessagesToParent("close-lightbox-parent", {
      cpmId: window.MC.USrc.Parent.cpmId,
    });
  });
  // Observe when resize
  const pageContainer = document.querySelector("div[data-page-container]");
  if (pageContainer) {
    const resizeObserver = new ResizeObserverPolyfill((entries) => {
      for (let entry of entries) {
        const contentRect = entry.contentRect;
        if (contentRect.height > 0) {
          resizeCallback();
        }
      }
    });
    resizeObserver.observe(pageContainer);
  }
};
