import { v4 } from "uuid";
import { initEvents } from "./events";
import { isWoxo } from "./helpers/woxo";
import { fireEvent } from "./util";
import pubSub from "pubsub-js";
import { isFunction } from "./helpers/javascript";

const timesOut = {
  maxIframeLoad: 50000,
  collectTime: 5000,
};

// This code for popups
const appendLightbox = (payload) => {
  const div = document.createElement("div");
  div.setAttribute("data-mc-lightbox", `lightbox-${payload.cmpId}`);
  div.innerHTML = `<iframe src="${payload.lightboxSrc}#lightbox-${payload.cmpId}" style="height:100%;width:100%;border:none:"></iframe>`;
  div.setAttribute(
    "style",
    "position:fixed;top:0;left:0;height:100vh;width:100vw;display:none;opacity:0;transition:0.5s;z-index:22222222222"
  );
  document.body.appendChild(div);
};

(() => {
  if (window.MC?.Loader) {
    // Already loaded in another script
    return;
  }
  // Configure basic namespaces
  window.MC = window.MC || {};
  window.MC.Loader = window.MC.Loader || {};
  window.MC.Pages = window.MC.Pages || {};
  window.MC.Pages.Components = window.MC.Pages.Components || [];
  window.MC.Pages.LoadedComponents = window.MC.Pages.LoadedComponents || [];
  window.MC.Pages.Definitions = window.MC.Pages.Definitions || {};
  window.MC.Pages.__fcRuntime = window.MC.Pages.__fcRuntime || {};
  window.MC.Pages.__fcRuntime.bundles =
    window.MC.Pages.__fcRuntime.bundles || {};
  window.MC.Pages.__fcRuntime.styles = window.MC.Pages.__fcRuntime.styles || {};
  window.MC.USrc = window.MC.USrc || {};
  window.MC.USrc.Parent = window.MC.USrc.Parent || {};
  window.MC.Vendors = window.MC.Vendors || {};
  window.MC.Vendors.PubSub = pubSub;
  window.EM = window.EM || {};
  window.EM.___fcRuntime = window.EM.___fcRuntime || {};
  window.EM.___fcRuntime.vendors = window.EM.___fcRuntime.vendors || {};

  // Init events
  initEvents({ appendLightbox });

  // Save times for remove component
  const timeToShow = {};
  // Convert all iframes in components
  const appendIframe = () => {
    const frames = document.querySelectorAll("div[data-mc-src]");
    frames.forEach((e) => {
      const baseUrl = e.getAttribute("data-mc-src").split("#");
      const social = baseUrl[1] || "woxo";
      let src = baseUrl[0];
      if (
        src.match(
          /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i
        )
      ) {
        src =
          process.env.WIDGETS_URL_TYPE === "prefix"
            ? `https://${src}.${process.env.WIDGETS_HOST}`
            : `https://${process.env.WIDGETS_HOST}/${src}`;
      }

      const component = {
        type: "iframe",
        cmpId: `mission-control-component-${v4()}`,
        settings: {
          loaderCls:
            e.getAttribute("data-mc-loader") === "false"
              ? false
              : e.getAttribute("data-mc-loader") || true,
          initialHeight: e.getAttribute("data-mc-height") || 200,
          src,
        },
      };

      component.element = e;
      component.title = `${social.charAt(0).toUpperCase()}${social.substring(
        1
      )} widgets for website`;
      if (e.hasAttributes()) {
        for (var attr in e.attributes) {
          if (
            e.attributes[attr] &&
            e.attributes[attr].name &&
            e.attributes[attr].name.startsWith("data-mc")
          )
            e.removeAttribute(e.attributes[attr].name);
        }
      }
      window.MC.Pages.Components.push(component);
    });
  };

  // Retrieve component container
  const retrieveContainer = (cmpId, noLoaded) => {
    const container = document.querySelector("[data-mc-cmp-id=" + cmpId + "]");
    if (container && noLoaded && container.getAttribute("data-mc-loaded"))
      return null;
    return container || null;
  };

  // Retrieve base container
  const retrieveBaseContainer = (cmpId) => {
    const container = document.querySelector("[data-mc-cmp-id=" + cmpId + "]");
    if (container && container.parentNode && container.parentNode.parentNode)
      return container.parentNode.parentNode;
    return null;
  };

  // Create loader for iframe component
  const loaderComponent = ({ cmpId, className }) => {
    if (!document.querySelector("[woxo-loader-style]")) {
      const style = document.createElement("style");
      style.innerText = `@keyframes mc-iframe-loader{0%{transform:scale(0,0);opacity:1;}100%{transform:scale(2,2);opacity:0;}},`;
      style.setAttribute("woxo-loader-style", !0);
      document.head.appendChild(style);
    }
    const loader = document.createElement("div");
    loader.innerHTML = `<div data-cmp-id="${cmpId}"style="position:absolute;height:100%;width:100%;z-index:11;top:0;left:0;">
    <div ${
      className !== true ? `class=${className}` : ""
    } style="height:100%;display:flex;align-items:center;justify-content:center;flex:1 1 0%;">
      <div style="width:80px;height:80px;display:inline-block;position:relative;">
      <div style="border:4px solid #4c4a4a;position:absolute;animation:mc-iframe-loader 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;border-radius:50%;height:36px;width:36px;top:25%;left:25%"></div>
      <div style="border:4px solid #4c4a4a;position:absolute;animation:mc-iframe-loader 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;border-radius:50%;height:36px;width:36px;top:25%;left:25%;animation-delay:-0.5s"></div>
      </div>
    </div>
</div>`;

    return loader;
  };

  // Create iframe
  const iframeComponent = ({ url, style, onLoad, title, cmpId }) => {
    const iframe = document.createElement("iframe");
    iframe.setAttribute("src", `${url}#${cmpId}`);
    iframe.style = style;
    iframe.setAttribute("title", title);
    iframe.setAttribute("data-hj-allow-iframe", !0);

    const messageHandler = (e) => {
      const { data } = e;
      if (
        data.event === "usrc:data-loaded" &&
        data.payload &&
        data.payload.id === cmpId
      )
        onLoad((data.payload && data.payload.height) || "auto");
    };

    window.addEventListener("message", messageHandler);
    timeToShow[iframe.cmpId] = new Date().getTime();
    setTimeout(() => {
      const loader = document.querySelector(`[data-cmp-id="${cmpId}"]`);
      if (loader) {
        const baseContainer = retrieveBaseContainer(cmpId);
        if (baseContainer) baseContainer.parentNode.removeChild(baseContainer);
      }
    }, timesOut.maxIframeLoad);

    return iframe;
  };

  // Render component type iframe into host page
  const renderIframe = (iframe) => {
    const container = retrieveContainer(iframe.cmpId);
    if (container) {
      if (iframe.settings && iframe.settings.initialHeight)
        container.style.height = `${iframe.settings.initialHeight}px`;
      container.style.position = `relative`;
      container.style.overflow = `hidden`;
      container.style["flex-basis"] = "100%";
      container.style.width = "100%";
      const url = (iframe.settings && iframe.settings.src) || null;
      if (isWoxo(url)) container.setAttribute("data-mc-manual-resizing", !0);
      if (iframe.settings && iframe.settings.loaderCls !== false)
        container.appendChild(
          loaderComponent({
            cmpId: iframe.cmpId,
            className: iframe.settings.loaderCls,
          })
        );
      container.appendChild(
        iframeComponent({
          url,
          style: `width:100%;height:100%;border:none;overflow:hidden;opacity:${
            isWoxo(url) ? 0 : null
          };transition:${isWoxo(url) ? "0.5s" : null};`,
          title: iframe.title,
          onLoad: (height) => {
            const el = container.querySelector("iframe");
            if (el) el.style.opacity = 100;
            if (height) container.style.height = `${height}px`;
            container.removeAttribute("data-mc-manual-resizing");
            const loader = container.querySelector(
              `[data-cmp-id="${iframe.cmpId}"]`
            );
            if (loader) loader.parentNode.removeChild(loader);
          },
          cmpId: iframe.cmpId,
        })
      );
      container.setAttribute("data-mc-loaded", !0);
      fireEvent("mc-rendered-component");
    }
  };

  // Load component bundle and css styles
  const load = (component) => {
    if (component.type === "iframe") renderIframe(component);
  };

  // Prepare components to begin load
  const prepare = () => {
    if (window.MC.Pages.Components.length) {
      const container = retrieveContainer(
        window.MC.Pages.Components[0].cmpId,
        true
      );
      if (container) {
        const component = window.MC.Pages.Components.shift();
        window.MC.Pages.LoadedComponents.push(component);
        load({ ...component });
      }
    } else {
      window.removeEventListener("scroll", prepare);
      window.removeEventListener("mc-rendered-component", prepare);
    }
  };

  // Create a component container for render into
  const createComponentDeclaration = (cmpConfig) => {
    if (
      !document.querySelector("div[data-mc-cmp-id='" + cmpConfig.cmpId + "']")
    ) {
      let parent =
        cmpConfig.element || document.querySelector("[data-page-container]");
      if (!parent) parent = document.body;
      const containerElement = document.createElement("article");
      const iDiv = document.createElement("div");
      iDiv.setAttribute("data-mc-cmp-id", cmpConfig.cmpId);
      if (cmpConfig.name) iDiv.setAttribute("data-mc-cmp", cmpConfig.name);
      if (cmpConfig.version)
        iDiv.setAttribute("data-mc-version", cmpConfig.version);
      containerElement.appendChild(iDiv);
      parent.setAttribute("data-cmp-container-id", cmpConfig.cmpId);
      parent.appendChild(containerElement);
    }
  };

  const retrieveTeam = () => {
    const script = document.querySelector("[data-usrc]");
    const url = script.getAttribute("src");
    let team = script.getAttribute("data-usrc");
    if (!team) {
      const hash = url.split("#");
      team = hash[1];
    }
    if (!window.MC.Pages.Team || !window.MC.Pages.Team._id) {
      window.MC.Pages.Team = window.MC.Pages.Team || {};
      window.MC.Pages.Team._id = team;
    }
  };

  const newWidgetObserver = new MutationObserver((mutations) => {
    mutations.forEach(({ type, addedNodes, removedNodes }) => {
      try {
        if (type === "childList") {
          if (addedNodes.length) {
            const node = addedNodes[0];
            let el = null;
            if (node.nodeType === Node.ELEMENT_NODE) {
              /**
               * @type {Element}
               */
              const nodeElement = node;
              if (nodeElement.hasAttribute("data-mc-src")) {
                el = nodeElement;
              } else {
                el = nodeElement.querySelector("div[data-mc-src]");
              }
            }
            if (
              el &&
              isFunction(el.hasAttribute) &&
              !el.hasAttribute("data-mc-observed")
            ) {
              el.setAttribute("data-mc-observed", !0);
            }
            window.MC.Loader.init();
          } else {
            removedNodes.forEach((node) => {
              let el = null;
              if (node.nodeType === Node.ELEMENT_NODE) {
                /**
                 * @type {Element}
                 */
                const nodeElement = node;
                el = nodeElement.hasAttribute("data-cmp-container-id")
                  ? nodeElement
                  : nodeElement.querySelector("div[data-cmp-container-id]");
              }
              if (el) {
                const index = window.MC.Pages.LoadedComponents.indexOf(
                  window.MC.Pages.LoadedComponents.find(
                    (lc) =>
                      lc.cmpId === el.getAttribute("data-cmp-container-id")
                  )
                );
                if (index !== -1) {
                  window.MC.Pages.LoadedComponents.splice(index, 1);
                }
              }
            });
          }
        }
      } catch (error) {}
    });
  });

  const normalize = () => {
    const components = [...window.MC.Pages.LoadedComponents];
    components.forEach((lc) => {
      if (!document.querySelector(`div[data-mc-cmp-id="${lc.cmpId}"]`)) {
        if (!window.MC.Pages.Components.includes((c) => c.cmpId === lc.cmpId))
          window.MC.Pages.Components.push(lc);
        window.MC.Pages.LoadedComponents.splice(0, 1);
      }
    });
  };

  // Init loader to begin load components
  window.MC.Loader.init = () => {
    retrieveTeam();
    if (
      window.MC &&
      window.MC.Pages &&
      Array.isArray(window.MC.Pages.Components)
    ) {
      normalize();
      appendIframe();
      window.MC.Pages.Components.forEach((component) => {
        createComponentDeclaration(component);
      });
      window.addEventListener("scroll", prepare, false);
      window.addEventListener("mc-rendered-component", prepare, false);
      fireEvent("mc-rendered-component");
    }
  };

  const entryPoint = () => {
    const widget = document.querySelector("div[data-mc-src]");
    if (widget != null) {
      const loading = widget.getAttribute("loading");
      if (loading === "lazy" && window.IntersectionObserver != null) {
        let intersectionObserver = new IntersectionObserver(
          (entries, observer) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                observer.disconnect();
                window.MC.Loader.init();
              }
            });
          }
        );
        intersectionObserver.observe(widget);
      } else {
        window.MC.Loader.init();
      }
    }

    newWidgetObserver.observe(document.body, {
      childList: true,
      subtree: true,
    });
  };

  if (document.readyState !== "loading") {
    entryPoint();
  } else {
    document.addEventListener("DOMContentLoaded", () => {
      entryPoint();
    });
  }
})();
