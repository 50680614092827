import { isFunction } from "./helpers/javascript";

(function () {
  if (typeof window.CustomEvent === "function") return false;

  const CustomEvent = (event, params) => {
    params = params || { bubbles: false, cancelable: false, detail: null };
    var evt = document.createEvent("CustomEvent");
    evt.initCustomEvent(
      event,
      params.bubbles,
      params.cancelable,
      params.detail
    );
    return evt;
  };

  window.CustomEvent = CustomEvent;
})();

// Allow interact with iframe via postMessage
export const sendMessages = (element, event, payload) => {
  if (element && element.postMessage && isFunction(element.postMessage))
    element.postMessage({ event, payload: payload || {} }, "*");
};

// Allow interact with parent via postMessage
export const sendMessagesToParent = (event, payload) => {
  if (window.parent !== window) {
    sendMessages(window.parent, event, payload);
  }
};

// Create event
export const createEvent = (name, detail) => {
  const event = new CustomEvent(name, {
    bubbles: true,
    cancelable: true,
    detail: detail || null,
  });
  return event;
};

// Fire event
export const fireEvent = (name, element, detail) => {
  element = element || window;
  const event = createEvent(name, detail);
  element.dispatchEvent(event);
};

export const request = ({
  method = "GET",
  url,
  headers,
  data,
  withCredentials = null,
}) =>
  new Promise((resolve, reject) => {
    headers = headers || {};
    if (typeof data === "object") {
      data = JSON.stringify(data);
      if (!Object.hasOwnProperty(headers, "Content-Type"))
        headers["Content-Type"] = "application/json";
    }
    const xhr = new XMLHttpRequest();
    if (withCredentials) xhr.withCredentials = withCredentials;
    xhr.open(method, url, true);
    Object.keys(headers).forEach((h) => {
      xhr.setRequestHeader(h, headers[h]);
    });
    xhr.onloadend = (response) => {
      if (xhr.status === 200) {
        let resp = {};
        if (response.currentTarget && xhr.responseText)
          resp = { data: xhr.responseText };
        try {
          resp = { data: JSON.parse(xhr.responseText) };
        } catch (e) {}
        resolve(resp);
      } else reject();
    };
    xhr.onerror = (error) => {
      reject(error);
    };
    xhr.onabort = (error) => {
      reject(error);
    };
    if (data) xhr.send(data);
    else xhr.send();
  });
